body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-container {
  display: block;
  width: 800px;
  padding: 15px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* background-image: url("../images/background-image.png"); */
  padding: 0;
  margin: 0;
  background-color: #fffcf1;
}

.profile-picture {
  float: left;
  width: 200px;
  margin-right: 20px;
  border: 7px solid #e7e7e7;
  border-radius: 20px;
}

p,
a {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
  text-align: left;
  color: #292929;
}

section h2 {
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #292929;
  margin-top: 14px;
  margin-bottom: 15px;
  text-align: left;
}

#form {
  display: none;
  position: absolute;
  width: 100%;
  max-width: 400px;
  padding: 15px;
  box-sizing: border-box;
  background-color: rgba(219, 218, 218, 0.88);
  border-radius: 6px;
  top: 50px;
  left: calc(50% - 200px);
}

.close-form {
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  right: -5px;
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 15px;
  top: -10px;
  cursor: pointer;
}

form label,
input,
textarea {
  display: block;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  line-height: 27px;
}

input[type="text"],
input[type="email"] {
  box-sizing: border-box;
  border: none;
  width: 99%;
  height: 38px;
  border-radius: 6px;
  padding: 7px;
}

textarea {
  box-sizing: border-box;
  border: none;
  width: 99%;
  height: 110px;
  margin-bottom: 15px;
  border-radius: 6px;
  padding: 7px;
}

input[type="button"] {
  border: none;
  background-color: #fff;
  padding: 8px 20px;
  border-radius: 6px;
  line-height: 18px;
}

input[type="button"]:hover {
  opacity: 0.7;
}

.text-error {
  color: red;
  margin-bottom: 15px;
}

.tab-nav ul {
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-nav ul li a {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}

.tab-nav .ui-tabs-panel {
  margin-top: 40px;
}

.tab-nav ul li.ui-tabs-active a {
  text-decoration: underline;
}

.portfolio-item,
.exp-item,
.testimonial-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  margin-top: 25px;
}

.portfolio-item .portfolio-img,
.exp-item .exp-img,
.testimonial-item .test-client-pic {
  min-width: 150px;
  max-width: 150px;
  height: 150px;
  background-color: #000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
}

.testimonial-item .test-client-pic {
  border-radius: 50%;
}

.exp-item .exp-img.grey-bg,
.testimonial-item .test-client-pic.grey-bg {
  background-color: #e7e7e7;
}

.portfolio-item .portfolio-img img,
.exp-item .exp-img img,
.testimonial-item .test-client-pic img {
  display: block;
  width: 100%;
}

.testimonial-item .test-client-pic img.female-avatar {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.portfolio-item .portfolio-content,
.exp-item .exp-content,
.testimonial-item .testimonial-content {
  padding: 0px 15px 15px 15px;
}

.portfolio-item .portfolio-content h3,
.exp-item .exp-content .exp-title {
  margin-top: 0;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 10px;
}

.portfolio-item .portfolio-content p,
.exp-item .exp-content p,
.testimonial-item .testimonial-content .testimonial {
  margin-top: 0;
  margin-bottom: 10px;
}

.portfolio-item .portfolio-content ul {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
}

.portfolio-item .portfolio-content ul li {
  margin-bottom: 7px;
  font-size: 18px;
  line-height: 22px;
}

.exp-item .exp-content .exp-period,
.testimonial-item {
  font-weight: bold;
}

.testimonial-content .test-client-info {
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
}

.client-list {
  display: block !important;
  /* text-align: left; */
}

.client-list li {
  display: inline-block;
  vertical-align: middle;
  width: 33%;
  margin-bottom: 20px;
  text-align: center;
}

.client-list li img {
  width: 150px;
  height: auto;
}

.slick-next:before,
.slick-prev:before {
  color: #605959;
}

.certificate-container img {
  width: 100%;
}

.skills-list,
.technology-list {
  display: block !important;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 40px;
}

.skills-list li {
  display: inline-block;
  width: 33%;
  text-align: center;
  margin-bottom: 20px;
}

.technology-list li {
  display: inline-block;
  width: 20%;
  text-align: center;
  margin-bottom: 20px;
}

.skills-list img {
  width: 64px;
}

.skills-list h4,
.technology-list h4 {
  margin-top: 10px;
  margin-bottom: 15px;
}

.technology-list img {
  width: 64px;
  background-color: #f2f1f1;
  border-radius: 50%;
  padding: 5px;
}

#form.contact-form {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: unset;
}

#form.contact-form label {
  text-align: left;
}

@media (max-width: 768px) {
  .App {
    align-items: flex-start;
  }

  .profile-picture {
    margin-right: 10px;
    width: 150px;
  }

  .section .img {
    max-width: 100%;
  }
  .section .content {
    margin-left: 0px;
  }
}

@media all and (max-width: 320px) {
  p {
    font-size: 14px;
    font-family: "PT Sans";
  }
  section h2 {
    font-size: 18px;
    font-family: "PT Sans";
  }
}

@media screen and (max-width: 1200px) {
  .main-container {
    width: 100%;
  }

  .tab-nav ul.ui-tabs-nav {
    display: block;
    white-space: nowrap;
    overflow: auto;
    text-align: center;
  }

  .tab-nav ul li.ui-tabs-tab {
    display: inline-block;
    padding: 0px 10px;
  }

  .tab-nav ul li:first-child {
    padding-left: 0;
  }

  .tab-nav ul li:last-child {
    padding-right: 0;
  }

  .client-list,
  .skills-list {
    white-space: unset;
  }

  .client-list li {
    width: 50%;
    padding: 0 !important;
  }
}

@media screen and (device-width: 768px) {
  p {
    font-size: x-large;
    font-family: "PT Sans";
    line-height: normal;
  }
  section h2 {
    font-size: xx-large;
  }
  img {
    width: 200px;
  }
}

@media screen and (max-width: 768px) {
  #form {
    width: 90% !important;
    left: 5% !important;
  }

  #form.contact-form {
    width: 90% !important;
    left: unset !important;
    top: 0;
  }

  .technology-list li {
    width: 33%;
  }

  .portfolio-item,
  .exp-item,
  .testimonial-item {
    display: block;
  }

  .portfolio-item .portfolio-img,
  .exp-item .exp-img,
  .testimonial-item .test-client-pic {
    margin-bottom: 15px;
  }

  .portfolio-item .portfolio-content,
  .exp-item .exp-content,
  .testimonial-item .testimonial-content {
    padding: 0;
  }
}
